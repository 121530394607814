<template>
  <div class="work-process w-100 mb-3 mb-md-4">
    <img :src="$t('img')" class="d-md-block d-none w-100" :alt="$t('alt')" />
    <img
      :src="$t('imgMobile')"
      class="d-block d-md-none w-100"
      :alt="$t('alt')"
    />
  </div>
</template>
<script>
import { $mqMixin } from "@/utils/mixins";
import ImgDesktop from "@/assets/img/project/SCHEME_DESKTOP.svg";
import ImgDesktopEn from "@/assets/img/project/SCHEME_DESKTOP_EN.svg";
import ImgMobile from "@/assets/img/project/SCHEME_MOBILE.svg";
import ImgMobileEn from "@/assets/img/project/SCHEME_MOBILE_EN.svg";

export default {
  name: "ProjektStartenProcess",
  mixins: [$mqMixin],
  i18n: {
    messages: {
      de: {
        img: ImgDesktop,
        imgMobile: ImgMobile,
        alt: "Prozessablauf: Begreifen, Entwerfen, Entwickeln"
      },
      en: {
        img: ImgDesktopEn,
        imgMobile: ImgMobileEn,
        alt: "Process: Define, Design, Develop"
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../styles/imports";

.work-process {
  background-color: $white;
  box-shadow: $box-shadow-card;
}
</style>
