<template>
  <div class="position-relative mb-3">
    <img
      class="w-100"
      src="@/assets/img/project/projektstarten.png"
      alt="Projektplanung am Whiteboard"
    />
    <div class="decoration position-relative">
      <div class="circle position-absolute" />
      <span class="decoration-title d-inline-block position-absolute"
        >Usability</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "ProjektStartenUsability"
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";
.decoration {
  display: none;
  bottom: 182px;
  max-height: 100%;
  left: -30px;
  z-index: 15;
  @include breakpoint(lg) {
    display: block;
  }
}
.circle {
  z-index: -5;
  width: 256px;
  max-width: 100%;
  height: 256px;
  background-color: $primary;
  border-radius: 50%;
  top: -30px;
  opacity: 0.3;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 344px;
    height: 210px;
    border-radius: 50%;
    background-color: $primary;
    opacity: 0.7;
    transform: rotate(-10deg);
    left: -100px;
  }
}
.decoration-title {
  font-size: 84px;
  line-height: 1;
  font-weight: $font-weight-bold;
  left: -80px;
  top: 50px;
  color: $white;

  &:after {
    content: "First";
    font-size: $font-size-sm;
    text-transform: uppercase;
    text-align: right;
    margin-top: 18px;
    margin-right: 20px;
    display: block;
  }
}
</style>
