function getCurrentBreakpoint() {
  try {
    const content = JSON.parse(
      window
        .getComputedStyle(document.querySelector("body"), ":before")
        .getPropertyValue("content")
    );
    return content;
  } catch (e) {
    return "xl";
  }
}

export const $mqMixin = {
  data() {
    return {
      mq: getCurrentBreakpoint()
    };
  },
  methods: {
    getCurrentBreakpoint() {
      this.mq = getCurrentBreakpoint();
    }
  },
  mounted() {
    window.addEventListener("resize", this.getCurrentBreakpoint);
  },
  onmounted() {
    window.removeEventListener("resize", this.getCurrentBreakpoint);
  }
};
