<template>
  <div class="listing position-relative pt-3 py-3 mb-3">
    <VerticalLines color="#333539" />
    <div class="container">
      <div class="v-grid">
        <div class="col">
          <h3 class="text-primary text-uppercase pr-3 mb-3">
            <slot />
          </h3>
        </div>
        <div
          v-for="item in items"
          :key="item.heading"
          style="color: white"
          class="col item-heading pb-3 pb-md-2 pr-1"
        >
          <h3 class="text-uppercase listing-title d-block mb-1">
            {{ item.heading }}
          </h3>
          <h5 class="text-primary listing-subtitle d-block mb-1">
            {{ item.sub }}
          </h5>
          <div class="text-bright font-size-sm content-listing">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerticalLines from "@/components/layouts/VerticalLines";

export default {
  name: "BlackListing",
  components: { VerticalLines },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";

.listing {
  background-color: $body-bg-dark;
  h3 {
    font-size: $line-height-base;
  }
  h5 {
    font-size: $font-size-sm;
    line-height: $line-height-base;
  }
  @include breakpoint(md) {
    padding: 0;
    h3 {
      font-size: $font-size-md;
    }
    h5 {
      line-height: $line-height-base;
    }
  }
}
</style>
