<template>
  <WhitePageLayout
    :page-icon="pageIcon"
    :title="$t('pageHeading')"
    :subtitle="$t('subTitle')"
  >
    <div class="container">
      <ProjektStartenProcess class="mb-3" />

      <StoryText>
        <StoryText class="text-center">
          <template #title>
            {{ $t("texts.0.title") }}
          </template>
          <div v-html="$t('texts.0.text')"></div>
        </StoryText>
      </StoryText>

      <div class="v-grid ">
        <div class="col-2 col-offset">
          <ProjektStartenUsability class="mb-4" />
          <StoryText>
            <div v-html="$t('texts.1.text')"></div>
          </StoryText>
        </div>
      </div>
    </div>
    <template #post-consultation>
      <BlackListing :items="$t('blackListing.items')">
        <div v-html="$t('blackListing.title')"></div>
      </BlackListing>
    </template>
  </WhitePageLayout>
</template>

<script>
import ProjektStartenProcess from "@/components/projekt-starten/ProjektStartenProcess";
import ProjektStartenUsability from "@/components/projekt-starten/ProjektStartenUsability";
import BlackListing from "@/components/projekt-starten/BlackListing";
import { metaInfo } from "@/utils/titleHelper";
import WhitePageLayout from "@/components/layouts/WhitePageLayout";
import StoryText from "@/components/story/StoryText";
import pageIcon from "@/assets/img/logo/binary.svg";

export default {
  name: "ProjektStarten",
  metaInfo() {
    return metaInfo(this.$t("title"), this.$t("descr"));
  },
  components: {
    StoryText,
    WhitePageLayout,
    BlackListing,
    ProjektStartenProcess,
    ProjektStartenUsability
  },
  data() {
    return {
      pageIcon
    };
  },
  i18n: {
    messages: {
      de: {
        title: "🚀 In drei Schritten zum erfolgreichen digitalen Produkt",
        descr:
          "Digitale Innovation leicht gemacht. Begreifen ✓ Entwerfen ✓ Entwickeln ✓ ",
        pageHeading: `Projekterfolg ist kein Zufall.`,
        subTitle: `Wir sorgen für einen glasklaren Projektverlauf und eine reibungslose Umsetzung.`,
        texts: [
          {
            title: `Ihr digitales Produkt als Full-Service Leistung.`,
            text: `
          Je nach Anforderung entwickeln wir mit unterschiedlichen Frameworks
          und Content-Management-Systemen digitale Lösungen in Form von
          <strong>Internetpräsenzen, Mobile Apps</strong> oder
          <strong>Individualsoftware</strong>. Dabei decken wir alle Bereiche
          der Entwicklung ab – von der Evaluation bis zur technischen Umsetzung.`
          },
          {
            title: ``,
            text: `<p class="mb-1">
              <strong>„Usability first“</strong> – bei der Interaktion zwischen
              Mensch und Maschine steht das positive Erlebnis im Vordergrund.
              Daher haben wir von Anfang an Ihre Nutzer im Fokus.
            </p>

            <p class="mb-1">
              Ob interaktive <strong>Prototypen</strong>, crossplattform
              <strong>Apps</strong> oder maßgeschneiderte
              <strong>Webanwendungen</strong> – wir begleiten Sie von der Idee
              bis zur Implementierung.
            </p>
            <p>
              Gemeinsam gestalten wir digitale Produkte, die Nutzer begeistern
              und Ihren Erfolg am Markt sichern.
            </p>`
          }
        ],
        blackListing: {
          title: `Damit <br /> Ihr Produkt zum Erfolg wird.`,
          items: [
            {
              heading: "Prototyping",
              sub: "Mehr Sicherheit und Effizienz durch präzise Planung.",
              text:
                "Wir klären welche Interaktionskonzepte gut funktionieren. Dadurch können teure Konstruktionsfehler bereits zu Beginn vermieden werden. Diese Methode steigert die Produktqualität und beschleunigt die Entwicklung."
            },
            {
              heading: "UX/UI-Design",
              sub:
                "Akzeptanz auf Anwenderseite mit nutzerfreundlichem und ästhetischem Produktdesign.",
              text:
                "Auch ein kompliziertes Softwareprodukt soll von adressierten Anwendern einfach erlernt und effizient benutzt werden können. Wir erarbeiten maßgeschneiderte Bedienkonzepte die begeistern."
            },
            {
              heading: "Entwicklung",
              sub:
                "Aktuelle Technolgien für leistungsfähige und zukunfssichere Anwendungen.",
              text:
                "Wir lassen Ihr Produkt nicht nur gut aussehen, sondern auch technisch ausgezeichnet performen. Dabei achten wir auf sinnvolle und wirtschaftliche Lösungen."
            }
          ]
        }
      },
      en: {
        title: "🚀 Three steps to a successful project",
        descr: "Digital innovation made easy. Define ✓ Design ✓ Develop ✓",
        pageHeading: `Project success is no accident`,
        subTitle: `We ensure transparency throughout your project and a smooth implementation.`,
        texts: [
          {
            title: `Full-service development of digital products`,
            text: `We develop <strong>websites</strong>, <strong>mobile apps</strong> and <strong>custom software</strong> to meet your requirements using a variety of frameworks and content management systems. Our teams cover all areas of development, from concept to technical implementation.`
          },
          {
            title: ``,
            text: `<p class="mb-1">
              <strong>“Usability first”</strong> – from the first interaction with your digital product, we want to ensure a positive experience. Therefore, we focus on your users right from the start.
            </p>

            <p class="mb-1">
             With interactive <strong>prototypes</strong>, cross-platform <strong>apps</strong>, and customized <strong>websites</strong> – we walk with you every step of the way, from idea to implementation.
            </p>
            <p>Together, we will design inspiring digital products and secure your place as a market leader.</p>`
          }
        ],
        blackListing: {
          title: `Ensure your project’s success`,
          items: [
            {
              heading: "Prototyping",
              sub: `Reduce risk and increase efficiency through precise planning.`,
              text: `We prototype to find the best interaction concept. Avoiding expensive design errors, right from the start, improves product quality and speeds up development.`
            },
            {
              heading: "UX/UI-Design",
              sub: `Improve user acceptance with a beautiful and user-friendly design.`,
              text: `Even complicated software should be fast to learn and easy to use. We develop interaction concepts that delight and inspire.`
            },
            {
              heading: "Development",
              sub: `Advanced technologies for future-proof and powerful applications.`,
              text: `We make sure your product both looks good and performs great technically, while also ensuring your solution is sensible and economical.`
            }
          ]
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/imports";
</style>
